const menuModules = [
    { name: "首页", to: "/dashboard", icon: "fa-solid fa-gauge-high", roles: ["user", "dev", "admin"] },
    { name: "服务器", to: "/user/server", icon: "fa-solid fa-server", roles: ["user", "dev", "admin"] },
    { name: "充值", to: "/user/buy", icon: "fa-solid fa-cart-shopping", roles: ["user", "dev", "admin"] },
    { name: "下载中心", to: "/user/download", icon: "fa-solid fa-download", roles: ["user", "dev", "admin"] },
    { name: "教程", to: "/user/docs", icon: "fa-solid fa-book", roles: ["user", "dev", "admin"] },
    { name: "联系我们", to: "/user/about", icon: "fa-solid fa-message", roles: ["user", "dev", "admin"] },
    { name: "个人中心", to: "/user/profile", icon: "fa-solid fa-user", roles: ["user", "dev", "admin"] },
]

export default menuModules;