import request from '../utils/request';

export function reqLogin(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  });
}

export function reqLogout() {
  return request({
    url: '/logout',
    method: 'post',
  });
}

export function reqRegister(data) {
  return request({
    url: '/register',
    method: 'post',
    data
  });
}