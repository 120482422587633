import React from "react";
import "./index.css";

const Course = () => {
  return (
    <>
      <div className="layout-course">
        <ul>
          <li>
            <a href="/wiki/windows.html" target="_blank" rel="noreferrer">
              <i className="fa-brands fa-windows"></i>
              <span>Windows</span>
            </a>
          </li>
          <li>
            <a href="/wiki/chrome_mac.html" target="_blank" rel="noreferrer">
              <i className="fa-brands fa-apple"></i>
              <span>macOS</span>
            </a>
          </li>
          <li>
            <a href="/wiki/shadowrocket.html" target="_blank" rel="noreferrer">
              <i className="fa-brands fa-apple"></i>
              <span>iPhone/iPad</span>
            </a>
          </li>
          <li>
            <a href="/wiki/android.html" target="_blank" rel="noreferrer">
              <i className="fa-brands fa-android"></i>
              <span>Android</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Course;
