import React, { useEffect } from "react";
import { connect } from "react-redux";
import { logout } from "../../../store/actions";
import logo from "../../../assets/leaf-96px.png";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuItem from "./MenuItem";
import menuModules from "../../../config/menuList";
import "./index.css";

const Sider = ({ user, logout, inactive = false, onClick = {} }) => {
	const removeActiveClassFromSubMenu = () => {
		document.querySelectorAll(".sub-menu").forEach((el) => {
			el.classList.remove("active");
		});
	};

	useEffect(() => {
		if (inactive) {
			removeActiveClassFromSubMenu();
		}
	}, [inactive]);

	useEffect(() => {
		let menuItems = document.querySelectorAll(".menu-item");
		menuItems.forEach((el) => {
			el.addEventListener("click", (e) => {
				const next = el.nextElementSibling;
				removeActiveClassFromSubMenu();
				menuItems.forEach((el) => el.classList.remove("active"));
				el.classList.toggle("active");
				if (next != null) {
					next.classList.toggle("active");
				}
			});
		});
	}, []);

	return (
		<>
			<div className="top-section">
				<div className="logo">
					<a href="/dashboard">
						<img src={logo} alt="COOTOS" />
						<span>COOTOS</span>
					</a>
				</div>
				<div
					onClick={onClick}
					id="toggle-menu-btn"
				>
					{inactive ? <MenuOpenIcon /> : <MenuIcon />}
				</div>
			</div>

			<div className="divider"></div>

			<ul>
				<li>
					<i className="fa-solid fa-magnifying-glass search-icon"></i>
					<input type="text" placeholder="search" />
					<span className="tooltip">搜索</span>
				</li>
				{menuModules.map((v, k) => (
					<MenuItem
						key={k}
						name={v.name}
						icon={v.icon}
						to={v.to}
						subMenus={v.subMenus || []}
					/>
				))}
			</ul>
		</>
	);
};

export default connect((state) => state.auth, { logout })(Sider);