import React from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../../store/actions";
import menuModules from "../../../config/menuList";
import "./index.css";

const Header = ({user, logout}) => {
  const location = useLocation();
  const { pathname } = location;
  return (
    <>
      <div className="layout-header-name">
        {menuModules?.find((e) => e.to === pathname)?.name}
      </div>
      <div className="layout-header-right-container">
        <div className="user-profile">
          <span className="avatar">{user?.username?.substr(0, 1).toUpperCase()}</span>
          <span className="user-info">{user?.username}</span>
        </div>
        <button className="logout" onClick={()=>logout()}>
          <i className="fa fa-sign-out"></i>
        </button>
      </div>
    </>
  );
};

export default connect((state) => state.auth, { logout })(Header);