import React, {useState}  from 'react';
import { connect } from "react-redux";
import './index.css';

const Dashboard = ({ user }) => {
  const subscribe = function(code){
    return "https://cootos.com/subscribe/" + code;
  }(user?.subscribe_code);
  const shadowrocket = function(code, username, expires) {
    return "sub://" + window.btoa("https://cootos.com/subscribe/" + code + "#COOTOS | " + username + " | Expires " + expires);
  }(user?.subscribe_code, user?.username, user?.expires);
  const [values, setValues] = useState({ios: false, android: false});
  const handleChange = (field, text)  => {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    setValues((pre) => {
			return { ...pre, [field]: true };
		});
    document.execCommand('copy');
    textField.remove();
    setTimeout(()=>{
      setValues((pre) => {
        return { ...pre, [field]: false };
      });
    }, 1000);
	};
  return (
    <>
      <div className="layout-dashboard">
        <ul>
          <li>
            <i>用户名</i>
            <span>{user?.username}</span>
          </li>
          <li>
            <i>到期时间</i>
            <span>{user?.expires}</span>
          </li>
          <li>
            <i>当前节点</i>
            <span>{user?.server_name}</span>
          </li>
          <li>
            <i>推荐码</i>
            <span>{user?.referrer_code}</span>
          </li>
          <li>
            <i>IOS</i>
            <button onClick={()=>handleChange('ios', shadowrocket)}>{`${values?.['ios'] ? "Copied" : "Click to copy"}`}</button>
          </li>
          <li>
            <i>Android</i>
            <button onClick={()=>handleChange('android', subscribe)}>{`${values?.['android'] ? "Copied" : "Click to copy"}`}</button>
          </li>
        </ul>
      </div>
    </>
  )
}

export default connect((state) => state.auth)(Dashboard);