import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { getUserInfo, login, register } from "../../store/actions";
import { verifyUserFormat } from "../../api/user";
import logo from "../../assets/leaf-96px.png";
import "./index.css";

const phonePattern =
	/^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8}$/;
const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,6})+$/;
const passwordPattern =
	/^(?=.*[A-Za-z])(?=.*[0-9$@$!%*?&.])([A-Za-z\d$@$!%*?&.]{6,20})$/;
const userPattern = /^(?=.*[A-Za-z0-9_])([A-Za-z0-9_]{6,18})$/;

const errorsMessage = {
	username: {
		required: { color: "error", text: "请填写用户名" },
		minLength: {
			color: "error",
			text: "6~18个字符，可使用字母、数字、下划线, 手机号码, 邮箱地址",
		},
		maxLength: {
			color: "error",
			text: "6~18个字符，可使用字母、数字、下划线, 手机号码, 邮箱地址",
		},
		invalid: { color: "error", text: "该用户名已被注册" },
		success: { color: "success", text: "恭喜，该用户名可以注册" },
	},
	password: {
		required: { color: "error", text: "请填写密码" },
		minLength: {
			color: "error",
			text: "密码长度应为6~20个字符，必需由英文字母、数字或特殊符号组成",
		},
		maxLength: {
			color: "error",
			text: "密码长度应为6~20个字符，必需由英文字母、数字或特殊符号组成",
		},
		invalid: { color: "error", text: "无效数据，请重新输入" },
		low: { color: "success", text: "密码强度: 低" },
		middle: { color: "success", text: "密码强度: 中" },
		high: { color: "success", text: "密码强度: 高" },
	},
	confirmPassword: {
		required: { color: "error", text: "请填写确认密码" },
		minLength: {
			color: "error",
			text: "The confirmPassword is must be at least 8 characters",
		},
		maxLength: {
			color: "error",
			text: "The confirmPassword is must be a maximum of 30 characters",
		},
		invalid: { color: "error", text: "两次填写的密码不一致" },
	},
	captcha: {
		required: { color: "error", text: "请填写验证码" },
	},
};

const InputField = ({
	icon,
	type = "text",
	placeholder = "",
	autoComplete = "off",
	onChange,
	onBlur,
}) => {
	const [visible, setVisibie] = useState(false);
	return (
		<div
			className={
				type === "password" ? "input-field password" : "input-field"
			}
		>
			<i className="input-label">
				<i className={icon}></i>
			</i>
			{type === "password" ? (
				<button
					className="input-password"
					onClick={() => {
						setVisibie(!visible);
					}}
				>
					<i className={`fa ${visible ? "fa-eye" : "fa-eye-slash"}`}></i>
				</button>
			) : (
				""
			)}
			<input
				type={
					type !== "password" ? type : visible ? "text" : "password"
				}
				placeholder={placeholder}
				autoComplete={autoComplete}
				onChange={onChange}
				onBlur={onBlur}
			/>
		</div>
	);
};

const TextAlert = ({ color = "error", text = "" }) => {
	const styles = {
		display: text !== "" ? "block" : "none",
		marginTop: "10px",
	};

	return (
		<div style={styles}>
			<Alert severity={color}>{text}</Alert>
		</div>
	);
};

const InputAlert = ({ color = "error", text = "" }) => {
	const styles = {
		display: text !== "" ? "inline-flex" : "none",
	};

	return (
		<div
			className={
				color !== "error"
					? "input-alert input-alert-success"
					: "input-alert input-alert-error"
			}
			style={styles}
		>
			<i className="fa fa-exclamation-circle"></i>
			<span>{text}</span>
		</div>
	);
};

const InputFieldGroup = ({
	icon,
	onAlert = {},
	type = "text",
	placeholder = "",
	autoComplete = "off",
	onChange,
	onBlur,
}) => {
	return (
		<>
			<InputField
				icon={icon}
				type={type}
				placeholder={placeholder}
				autoComplete={autoComplete}
				onChange={onChange}
				onBlur={onBlur}
			/>
			<InputAlert {...onAlert} />
		</>
	);
};

const SignUpFormField = ({ register }) => {
	const [captcha, setCaptcha] = useState("/captcha");
	const [values, setValues] = useState({'referral': localStorage.getItem('REFERRAL_CODE')|| ''});
	const [errors, setErrors] = useState({});
	const [alert, setAlert] = useState(null);

	const reloadCaptcha = () => {
		setCaptcha("/captcha?id=" + new Date().getTime());
		setErrors((pre) => {
			return { ...pre, captcha: null };
		});
	};

	const handleChange = (fieldName) => (event) => {
		setAlert(null);
		setErrors((pre) => {
			return { ...pre, [fieldName]: null };
		});
		setValues((pre) => {
			return { ...pre, [fieldName]: event.target.value };
		});
	};

	const handleSignUp = () => {
		console.log(values)
		let isValid = false;
		const fieldForm = [
			"username",
			"password",
			"confirmPassword",
			"captcha",
		];
		const validateForm = [
			validateUsername,
			validatePassword,
			validateConfirmPassword,
			validateCaptcha,
		];
		for (let i in fieldForm) {
			let hasError = "";
			if (fieldForm[i] === "confirmPassword") {
				hasError = validateForm[i](
					values?.[fieldForm[i]],
					values?.["password"]
				);
			} else {
				hasError = validateForm[i](values?.[fieldForm[i]]);
			}
			setErrors((pre) => {
				return {
					...pre,
					[fieldForm[i]]: hasError
						? errorsMessage?.[fieldForm[i]]?.[hasError]
						: "",
				};
			});
			if (!!hasError) {
				isValid = true;
			}
		}
		if (isValid) {
			return;
		}
		register({ ...values })
			.then((repsonse) => {
				setAlert({ color: "success", text: "注册成功" });
			})
			.catch((error) => {
				setAlert({ color: "error", text: "注册失败" });
				reloadCaptcha();
			});
	};

	const validateUsername = (value) => {
		if (!value || value === "" || value === "undefined") {
			return "required";
		}
		if (value.length < 6) {
			return "minLength";
		}
		if (value.length > 25) {
			return "maxLength";
		}
		if (/^[0-9]+$/.test(value)) {
			if (phonePattern.test(value)) {
				return "";
			}
			return "minLength";
		}
		if (emailPattern.test(value)) {
			return "";
		}
		if (userPattern.test(value)) {
			return "";
		}
		return "minLength";
	};

	const validatePassword = (value) => {
		if (!value || value === "" || value === "undefined") {
			return "required";
		}
		if (value.length < 6) {
			return "minLength";
		}
		if (value.length > 20) {
			return "maxLength";
		}
		if (passwordPattern.test(value)) {
			return "";
		}
		return "maxLength";
	};

	const validateConfirmPassword = (value, password) => {
		if (!value || value === "" || value === "undefined") {
			return "required";
		}
		if (value !== password) {
			return "invalid";
		}

		return "";
	};

	const validateCaptcha = (value) => {
		if (!value || value === "" || value === "undefined") {
			return "required";
		}

		if (value?.length !== 5) {
			return "required";
		}

		if (/^[0-9]+$/.test(value)) {
			return "";
		}
		return "required";
	};

	const validateField = (fieldName) => (event) => {
		let hasError = "";
		setValues((pre) => {
			return { ...pre, [fieldName]: event.target.value };
		});
		switch (fieldName) {
			case "username":
				hasError = validateUsername(event.target.value);
				if (!!hasError) {
					setErrors((pre) => {
						return {
							...pre,
							[fieldName]: hasError
								? errorsMessage?.[fieldName]?.[hasError]
								: "",
						};
					});
					break;
				}
				verifyUserFormat(event.target.value).then((response) => {
					const { code } = response;
					if (code === 200) {
						hasError = "success";
					} else {
						hasError = "invalid";
					}
					setErrors((pre) => {
						return {
							...pre,
							[fieldName]: hasError
								? errorsMessage?.[fieldName]?.[hasError]
								: "",
						};
					});
				});
				break;
			case "password":
				hasError = validatePassword(event.target.value);
				if (!hasError) {
					hasError = "middle";
				}
				setErrors((pre) => {
					return {
						...pre,
						[fieldName]: hasError
							? errorsMessage?.[fieldName]?.[hasError]
							: "",
					};
				});
				break;
			case "confirmPassword":
				hasError = validateConfirmPassword(
					event.target.value,
					values?.["password"]
				);
				setErrors((pre) => {
					return {
						...pre,
						[fieldName]: hasError
							? errorsMessage?.[fieldName]?.[hasError]
							: "",
					};
				});
				break;
			case "captcha":
				hasError = validateCaptcha(event.target.value);
				setErrors((pre) => {
					return {
						...pre,
						[fieldName]: hasError
							? errorsMessage?.[fieldName]?.[hasError]
							: "",
					};
				});
				break;
			default:
				break;
		}
	};

	return (
		<div className="form-container sign-up-container">
			<div className="form-layout">
				<h1 className="title">创建用户</h1>
				<InputFieldGroup
					onAlert={errors?.["username"]}
					icon={"fa fa-user"}
					placeholder="用户名"
					onChange={handleChange("username")}
					onBlur={validateField("username")}
				/>
				<InputFieldGroup
					onAlert={errors?.["password"]}
					icon={"fa fa-lock"}
					type="password"
					placeholder="密码"
					onChange={handleChange("password")}
					onBlur={validateField("password")}
				/>
				<InputFieldGroup
					onAlert={errors?.["confirmPassword"]}
					icon={"fa fa-lock"}
					type="password"
					placeholder="确认密码"
					onChange={handleChange("confirmPassword")}
					onBlur={validateField("confirmPassword")}
				/>
				<div className="referral-code">
					<input type="text" placeholder="推荐码" onChange={handleChange("referral")} onBlur={handleChange("referral")} defaultValue={values?.['referral']} />
				</div>
				<div className="captcha-box">
					<input
						type="text"
						placeholder="验证码"
						onChange={handleChange("captcha")}
						onBlur={validateField("captcha")}
					/>
					<img src={captcha} alt="captcha" />
					<button
						className="reload-btn"
						onClick={() => {
							reloadCaptcha();
						}}
					>
						<i className="fa fa-refresh"></i>
					</button>
				</div>
				<InputAlert {...errors?.["captcha"]} />
				<div className="input-checkbox">
					<input
						type="checkbox"
						name="checkbox"
						className="check-box"
						defaultChecked
					/>
					<label htmlFor="checkbox">我同意条款和协议</label>
				</div>
				<button className="form-layout-button" onClick={handleSignUp}>
					注册
				</button>
				<TextAlert {...alert} />
			</div>
		</div>
	);
};

const SignInFormField = ({ login }) => {
	const [values, setValues] = useState({});
	const [errors, setErrors] = useState({});
	const [alert, setAlert] = useState({});

	const handleChange = (field) => (event) => {
		setErrors({});
		setAlert({});
		setValues((pre) => {
			return { ...pre, [field]: event.target.value };
		});
	};

	const handleSignIn = () => {
		let isValid = false;
		const fieldForm = ["username", "password"];
		const validateForm = [validateUsername, validatePassword];
		for (let i in fieldForm) {
			const hasError = validateForm[i](values?.[fieldForm[i]]);
			setErrors((pre) => {
				return {
					...pre,
					[fieldForm[i]]: hasError
						? errorsMessage?.[fieldForm[i]]?.[hasError]
						: "",
				};
			});
			if (!!hasError) {
				isValid = true;
			}
		}
		if (isValid) {
			return;
		}
		login({ ...values })
			.then((repsonse) => {
				setAlert({ color: "success", text: "登录成功" });
			})
			.catch((error) => {
				setAlert({ color: "error", text: "登录失败" });
			});
	};

	const validateUsername = (value) => {
		if (!value || value === "" || value === "undefined") {
			return "required";
		}
		if (value.length < 5) {
			return "minLength";
		}
		if (value.length > 25) {
			return "maxLength";
		}
		return "";
	};

	const validatePassword = (value) => {
		if (!value || value === "" || value === "undefined") {
			return "required";
		}
		if (value.length < 6) {
			return "minLength";
		}
		if (value.length > 25) {
			return "maxLength";
		}

		return "";
	};

	const validateField = (fieldName) => (event) => {
		let hasError = "";
		setValues((pre) => {
			return { ...pre, [fieldName]: event.target.value };
		});
		switch (fieldName) {
			case "username":
				hasError = validateUsername(values?.[fieldName]);
				setErrors((pre) => {
					return {
						...pre,
						[fieldName]: hasError
							? errorsMessage?.[fieldName]?.[hasError]
							: "",
					};
				});
				break;
			case "password":
				hasError = validatePassword(values?.[fieldName]);
				setErrors((pre) => {
					return {
						...pre,
						[fieldName]: hasError
							? errorsMessage?.[fieldName]?.[hasError]
							: "",
					};
				});
				break;
			default:
				break;
		}
	};

	return (
		<div className="form-container sign-in-container">
			<div className="form-layout">
				<h1 className="title">登录</h1>
				<InputFieldGroup
					onAlert={errors?.["username"]}
					icon={"fa fa-user"}
					placeholder="用户名"
					onChange={handleChange("username")}
					onBlur={validateField("username")}
				/>
				<InputFieldGroup
					onAlert={errors?.["password"]}
					icon={"fa fa-lock"}
					type="password"
					placeholder="密码"
					onChange={handleChange("password")}
					onBlur={validateField("password")}
				/>
				<div className="input-checkbox">
					<input
						type="checkbox"
						name="checkbox"
						className="check-box"
					/>
					<label htmlFor="checkbox">记住密码</label>
				</div>
				<button className="form-layout-button" onClick={handleSignIn}>
					登录
				</button>
				<TextAlert {...alert} />
			</div>
		</div>
	);
};

const SwitchFormField = ({ visible = false, setVisible = {} }) => {
	return (
		<div className="switch-container">
			<div className={`switch-layout-box ${!visible ? "selected" : ""}`}>
				<button
					className="switch-layout-button"
					onClick={() => {
						setVisible(false);
					}}
				>
					登录
				</button>
			</div>
			<div className={`switch-layout-box ${visible ? "selected" : ""}`}>
				<button
					className="switch-layout-button"
					onClick={() => {
						setVisible(true);
					}}
				>
					注册
				</button>
			</div>
		</div>
	);
};

const Logo = () => {
	return (
		<div className="logo-layout">
			<a href="/" className="logo">
				<img src={logo} alt="COOTOS" />
				COOTOS
			</a>
		</div>
	);
};

const Sign = ({ token, isAuthenticated, login, register, getUserInfo }) => {
	const [visible, setVisible] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const query = new URLSearchParams(window.location.search);
		const rc = localStorage.getItem("REFERRAL_CODE") || query.get('rc')
		if (rc && rc !== localStorage.getItem("REFERRAL_CODE")) {
			localStorage.setItem("REFERRAL_CODE", rc)
		}
		if (token) {
			getUserInfo(token);
		}
		if (isAuthenticated) {
			navigate("/dashboard");
		}
	});

	return (
		<div className="sign-layout">
			<Logo />
			<div
				className={`container ${!visible ? "signin-panel-active" : "signup-panel-active"
					}`}
			>
				<SwitchFormField visible={visible} setVisible={setVisible} />
				<SignInFormField login={login} />
				<SignUpFormField register={register} />
			</div>
		</div>
	);
};

export default connect((state) => state.auth, { login, register, getUserInfo })(
	Sign
);
