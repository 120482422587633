import React from "react";
import './index.css';

const Download = () => {
  return (
    <>
      <div className="primary-download">
        <div className="logo"></div>
        <h2 className="title">客户端下载</h2>
        <ul className="download-menu">
          <li className="download-item">
            <div className="download-title">
              <i className="fa-brands fa-windows"></i>
              <span>Windows</span>
            </div>
            <a className="download-btn" href="/dl/proxy-x64.exe" target="_blank" rel="noreferrer">
              <span>Download</span>
              <i className="fa-solid fa-download"></i>
            </a>
          </li>
          <li className="download-item">
            <div className="download-title">
              <i className="fa-brands fa-apple"></i>
              <span>macOS</span>
            </div>
            <a className="download-btn" href="/dl/proxy.dmg" target="_blank" rel="noreferrer">
              <span>Download</span>
              <i className="fa-solid fa-download"></i>
            </a>
          </li>
          <li className="download-item">
            <div className="download-title">
              <i className="fa-brands fa-apple"></i>
              <span>iPhone / iPad</span>
            </div>
            <a
              className="download-btn"
              href="https://apps.apple.com/us/app/shadowrocket/id932747118"
              target="_blank"
              rel="noreferrer"
            >
              <span>Download</span>
              <i className="fa-solid fa-download"></i>
            </a>
          </li>
          <li className="download-item">
            <div className="download-title">
              <i className="fa-brands fa-android"></i>
              <span>Android</span>
            </div>
            <a
              className="download-btn"
              href="/dl/cfa-2.5.12-foss-arm64-v8a-release.apk"
              target="_blank"
              rel="noreferrer"
            >
              <span>Download</span>
              <i className="fa-solid fa-download"></i>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Download;
