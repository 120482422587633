import React, { useState } from "react";
import { createPayment } from '../../api/payment';
import "./index.css";

const pricingList = [
	{
		name: "季卡会员",
		plan: "quarter",
		dollar: "$",
		price: 6.90,
		text: "/quarter",
		tag: "3个月",
		active: false,
	},
	{
		name: "年卡会员",
		plan: "year",
		dollar: "$",
		price: 21.90,
		text: "/year",
		tag: "12个月",
		active: true,
	},
	{
		name: "月卡会员",
		plan: "month",
		dollar: "$",
		price: 2.90,
		text: "/month",
		tag: "1个月",
		active: false,
	},
];

const PricingForm = ({ active = false, name, plan, dollar, price, text, tag }) => {
	const [values, setValues] = useState({})
	const handleChange = (fieldName) => {
		var timestamp = new Date().getTime();
		if (values[fieldName]) {
			if ((timestamp - values[fieldName]) > 1000) {
				setValues((pre) => {
					return { ...pre, [fieldName]: timestamp };
				});
			}
		} else {
			setValues((pre) => {
				return { ...pre, [fieldName]: timestamp };
			});
		}
		
		return values?.[fieldName] || timestamp
	}
	const handleClick = (jsonData) => {
		createPayment({...jsonData}).then((response)=>{
			const { code } = response;
			if (code === 200) {
				setValues({});
				const { data } = response;
				window.location.href = `${window.location.protocol}//${window.location.host}/checkout/${data?.id}`;
			} else {
				console.log('error');
			}
		})
	}

	return (
		<>
			<div className="pricing-card-box">
				<h3>{name}</h3>
				<h1 className={`${active ? "active" : ""}`}>
					<i>{dollar}</i>
					{price}
					<span>{text}</span>
				</h1>
				<button onClick={()=>{handleClick({'plan': plan, 'timestamp': handleChange(plan)})}}>充值</button>
				<ul>
					<li>{tag}</li>
					<li>7*24小时服务</li>
				</ul>
			</div>
		</>
	);
};

const Buy = () => {
	return (
		<>
			<div className="layout-pricing">
				{pricingList.map((el, i) => {
					return <PricingForm key={i} {...el} />;
				})}
			</div>
		</>
	);
};

export default Buy;